import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import HeaderMeta from '../components/HeaderMeta';

import {
  HeadingBox,
  ResponsiveMaxWidthFrame,
  SectionGroup,
  TitleBox,
  SubHeadlineBox,
  Button,
  FlexRow,
  SectionHeadingBox,
  ResponsiveImage,
  ResponsiveMultiColumn,
  TextImageFrame,
  LinkBox,
  vectors,
  HtmlBody,
} from "@minware/ui-minware";

export const ReportsScorecardPageTemplate = ({
  header,
  body,
  bodyProps,
  pathName,
}) => {
  const headerWithCanon = {
    ...header,
    canonicalUrl: pathName || '',
  };

  const {
    title,
    heading,
    subHeading,
    ctaButton,
    alternateButton,
    image,
    grayBox,
    valueProps,
    docLinks,
    faqTitle,
  } = bodyProps;

  return (
    <Layout>
      <HeaderMeta header={headerWithCanon}/>

      <ResponsiveMaxWidthFrame maxWidth="600px" spacing={20}>
        <SectionGroup>
          <SectionHeadingBox centered isH1>
            {title}
          </SectionHeadingBox>
          <TitleBox isH2>{heading}</TitleBox>
          <SubHeadlineBox centered isMain>
            {subHeading}
          </SubHeadlineBox>
        </SectionGroup>
        <FlexRow gap={20}>
          <Button cta link={ctaButton.link}>
            {ctaButton.text}
          </Button>
          <Button ctaSecondary link={alternateButton.link}>
            {alternateButton.text}
          </Button>
        </FlexRow>
      </ResponsiveMaxWidthFrame>

      <ResponsiveMaxWidthFrame maxWidth="1040px" marginTop={60} marginBottom={60}>
        <ResponsiveImage
          src={image.vectorName ? vectors[image.vectorName]() :  `/img/${image.imageFile}`}
          title={image.title}
          origWidth={image.origWidth}
          origHeight={image.origHeight}
          minWidth={image.minWidth}
          maxWidth={image.maxWidth ?? undefined}
          lrPad={40}
        />
      </ResponsiveMaxWidthFrame>

      <ResponsiveMaxWidthFrame maxWidth="800px">
        <SectionGroup showBackground>
          <HeadingBox
            tagLevel="h2"
            align="center"
            justifyContent="center"
            forceMargin="20px 0 10px 0"
          >
            {grayBox.heading}
          </HeadingBox>
          {grayBox.subHeadings.map(curSubHeading => (
            <SubHeadlineBox centered>
              {curSubHeading}
            </SubHeadlineBox>
          ))}
        </SectionGroup>
      </ResponsiveMaxWidthFrame>

      <ResponsiveMaxWidthFrame maxWidth="1040px" marginTop={20} marginBottom={20} spacing={20}>
        {!valueProps ? null : (
          <ResponsiveMultiColumn firstColWidth="1" breakpoint={800}>
            {valueProps.map(({ heading, subHeading, imageName }) => (
              <TextImageFrame
                heading={heading}
                subHeadline={subHeading}
                image={vectors[imageName]}
                showSmallImage
                hideBg
              />
            ))}
          </ResponsiveMultiColumn>
        )}
        {(docLinks ?? []).map(({ text, url }) => (
          <LinkBox link={url} size="body">
            {text}
          </LinkBox>
        ))}
      </ResponsiveMaxWidthFrame>

      <ResponsiveMaxWidthFrame maxWidth="640px" spacing={0}>
        <HeadingBox
          level="h1"
          tagLevel="h2"
          align="center"
          justifyContent="center"
          forceMargin="30px 0 0 0"
        >
          {faqTitle}
        </HeadingBox>

        <HtmlBody noListIndent={true} children={body}/>

        <FlexRow gap={20}>
          <Button cta link={ctaButton.link}>
            {ctaButton.text}
          </Button>
          <Button ctaSecondary link={alternateButton.link}>
            {alternateButton.text}
          </Button>
        </FlexRow>
      </ResponsiveMaxWidthFrame>
    </Layout>
  )
}

ReportsScorecardPageTemplate.propTypes = {
  pretitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const ReportsScorecardPage = ({ data, location }) => {
  const { main } = data;

  const { pathname } = location;
  const stripSlashPath = pathname.length > 0 && pathname[pathname.length - 1] === '/'
    ? pathname.substr(0, pathname.length - 1)
    : pathname;

  return (
    <ReportsScorecardPageTemplate
      header={main.frontmatter.header}
      body={main.html}
      bodyProps={main.frontmatter.bodyProps}
      pathName={stripSlashPath}
    />
  )
}

ReportsScorecardPage.propTypes = {
  data: PropTypes.object.isRequired,
}


export default ReportsScorecardPage;

export const ReportsScorecardPageQuery = graphql`
  query ReportsScorecardPage($id: String!) {
    main: markdownRemark(id: { eq: $id }) {
      ...HeaderMeta
      html
      frontmatter {
        bodyProps {
          title
          heading
          subHeading
          ctaButton {
            text
            link
          }
          alternateButton {
            text
            link
          }
          image {
            imageFile
            vectorName
            origWidth
            origHeight
            minWidth
            maxWidth
            title
          }
          grayBox {
            heading
            subHeadings
          }
          valueProps {
            heading
            subHeading
            imageName
          }
          docLinks {
            text
            url
          }
          faqTitle
        }
      }
    }
  }
`
